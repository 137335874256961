// ExperienceComponent.js

import React from 'react';
import './Component.css'; // Import the CSS file for styling
import { useMediaQuery } from 'react-responsive';
import LinkIcon from './link.svg';

const PublicationComponent = ({ title, location, description, link }) => {

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  return (
    <div>
    {isMobile ? 

    <a  href= {link} target="_blank" rel="noreferrer">
    <div className="blockexp-container_mob">
          <div className="blockexp">
      <b contentEditable>{title} • {location} </b><img src= {LinkIcon} className="svgicon"  alt="link icon"/>
      <p contentEditable>{description}</p>
    </div>
    </div>
    </a>

        :

    <a  href= {link} target="_blank" rel="noreferrer">
    <div className="blockexp-container">
          <div className="blockexp">

      <b contentEditable>{title} • {location} </b> 
      <p contentEditable>{description}</p>
    </div>
    </div>
    </a>

}
</div>
  );
};

export default PublicationComponent;
