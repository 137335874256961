import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MobileInterface from './Mobile.js';
import NonMobileInterface from './Desktop.js';

function App() {
  const isMobile = useMediaQuery({ maxWidth: 1050 });

  useEffect(() => {
    // Create meta element
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1, viewport-fit=cover';

    // Append meta element to head
    document.head.appendChild(metaTag);

    // Clean up on component unmount
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="body">
      {isMobile ? <MobileInterface /> : <NonMobileInterface />}
    </div>
  );
}

export default App;
