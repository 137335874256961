
// Desktop.js


// App.js
import React, { useState, useEffect } from 'react';
import ExperienceComponent from './Components/ExperienceComponents';
import ProjectComponent from './Components/ProjectComponents';
import PublicationComponent from './Components/PublicationComponents';
import './App.css';
import LinkedInIcon from './Components/linkedin.svg';
import MailIcon from './Components/mail.svg';
import GithubIcon from './Components/github.svg';
import ResumeIcon from './Components/resume.svg';
  


function NonMobileInterface() {
  const [isAboutVisible, setIsAboutVisible] = useState(true);
  const [isExperienceVisible, setIsExperienceVisible] = useState(false);
  const [isProjectsVisible, setIsProjectsVisible] = useState(false);
  const [isPublicationsVisible, setIsPublicationsVisible] = useState(false);

  useEffect(() => {
    const handleGlobalEvent = () => {

      const aboutElement = document.querySelector('.about');
      const experienceElement = document.querySelector('.experience');
      const projectsElement = document.querySelector('.projects');
      const publicationsElement = document.querySelector('.publications');

      const aboutRect = aboutElement.getBoundingClientRect();
      const experienceRect = experienceElement.getBoundingClientRect();
      const projectsRect = projectsElement.getBoundingClientRect();
      const publicationsRect = publicationsElement.getBoundingClientRect();

      const windowHeight = window.innerHeight;
      const windowCenter = windowHeight / 2;

      // Calculate distances of each element's center to the window center
      const aboutDistanceToCenter = Math.abs((aboutRect.top + aboutRect.bottom) / 2 - windowCenter);
      const experienceDistanceToCenter = Math.abs((experienceRect.top + experienceRect.bottom) / 2 - windowCenter);
      const projectsDistanceToCenter = Math.abs((projectsRect.top + projectsRect.bottom) / 2 - windowCenter);
      const publicationsDistanceToCenter = Math.abs((publicationsRect.top + publicationsRect.bottom) / 2 - windowCenter);

      // Determine which element is closest to the window center
      const minDistance = Math.min(aboutDistanceToCenter, experienceDistanceToCenter, projectsDistanceToCenter, publicationsDistanceToCenter);

      // Set visibility based on the closest element to the window center
      setIsAboutVisible(minDistance === aboutDistanceToCenter);
      setIsExperienceVisible(minDistance === experienceDistanceToCenter);
      setIsProjectsVisible(minDistance === projectsDistanceToCenter);
      setIsPublicationsVisible(minDistance === publicationsDistanceToCenter);
    };

    // Attach event listeners to the document or window object
    window.addEventListener('click', handleGlobalEvent);
    window.addEventListener('wheel', handleGlobalEvent);
    window.addEventListener('scroll', handleGlobalEvent);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('click', handleGlobalEvent);
      window.removeEventListener('wheel', handleGlobalEvent);
      window.removeEventListener('scroll', handleGlobalEvent);
    };
  }, []);

  const scrollTo = (id) => {
  const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }; 

 const handleLinkClick = (id) => {
    return () => {
      // Set the state and then scroll
      if (id === 'about') {
        setIsAboutVisible(true);
        setIsExperienceVisible(false);
        setIsProjectsVisible(false);
        setIsPublicationsVisible(false);
      } else if (id === 'experience') {
        setIsAboutVisible(false);
        setIsExperienceVisible(true);
        setIsProjectsVisible(false);
        setIsPublicationsVisible(false);
      } else if (id === 'projects') {
        setIsAboutVisible(false);
        setIsExperienceVisible(false);
        setIsProjectsVisible(true);
        setIsPublicationsVisible(false);
      } else if (id === 'publications') {
        setIsAboutVisible(false);
        setIsExperienceVisible(false);
        setIsProjectsVisible(false);
        setIsPublicationsVisible(true);
      }
      scrollTo(id);
    };
  };

  return (
    <div className="container">

      <div className="column_left">
        <h1>Joost Remmers</h1>
        <h3>Hydraulic • Geotechnical • Marine • Data</h3>
        <p>Multi-disciplinary lead engineer creating 
        solutions which venture beyond the standard.</p>
        <br></br><br></br>

         <h4 className={isAboutVisible ? 'highlighted' : ''}>
          <a href="#about" onClick={handleLinkClick('about')}>
            {isAboutVisible ? 'About →' : 'About'}
          </a>
        </h4>
        <h4 className={isExperienceVisible ? 'highlighted' : ''}>
          <a href="#experience" onClick={handleLinkClick('experience')}>
            {isExperienceVisible ? 'Experience →' : 'Experience'}
          </a>
        </h4>
        <h4 className={isProjectsVisible ? 'highlighted' : ''}>
          <a href="#projects" onClick={handleLinkClick('projects')}>
            {isProjectsVisible ? 'Projects →' : 'Projects'}
          </a>
        </h4>
        <h4 className={isPublicationsVisible ? 'highlighted' : ''}>
          <a href="#publications" onClick={handleLinkClick('publications')}>
            {isPublicationsVisible ? 'Publications →' : 'Publications'}
          </a>
        </h4>
                  <p className="bottomlined">
            <a href="https://www.linkedin.com/in/joost-remmers-b1457761/" target="_blank" rel="noreferrer">
             <img src={LinkedInIcon} alt="Linkedin" className="colored-svg" />
             </a>
            <a href="mailto:contact@joostremmers.nl" target="_blank" rel="noreferrer">
             <img src={MailIcon} alt="Mail" className="colored-svg" />
            </a>
            <a href="https://github.com/bestestimate" target="_blank" rel="noreferrer">
             <img src={GithubIcon} alt="Github" className="colored-svg" />
            </a>
            <a href="./resume_joost_remmers.pdf" target="_blank" rel="noreferrer">
             <img src={ResumeIcon} alt="Resume" className="colored-svg" />
            </a>
         </p>
      </div>

      <div className="column_right">
    
        <div className="about" id="about">
        <p>
        My career in engineering started in 2012 when I began my studies in <b>Civil & Offshore Engineering</b> at Delft University of Technology. 
        Over the last ten years, I worked in different roles on many multi-disciplinary projects involving <b>structural, mechanical, hydraulic and geotechnical design. </b>  
        I combine these disciplines with my passion for experiments and programming.
        </p>
        <p>
        In recent years, I focused on developing new <b>innovations</b> from scratch, combining a <b>technical and commercial role</b>.
        It is most fun when when my team develops a pragmatic as well as disruptive solution at the same time.
        </p>
        <p>
        Next to my job, I practice my <b>data science</b> skills by creating software and writing a blog on statistics in (geotechnical) engineering. 
        During evenings and weekends, I double as <b>an amateur chef</b> and enjoy visiting <b>quirky museums</b>.
        </p>
       </div>
      
      <div className="experience"  id="experience">

      <ExperienceComponent
        date="2019 - 2024"
        position="Project → Senior → Lead Engineer"
        company="TWD"
        location="Rotterdam (NL)"
        description="Create engineering solutions for foundation installation of next generation Offshore Wind Farms, geotechnical lead off the company and business development within the field of experimental and operational work. Founder and responsible for technical and commercial aspects of the material testing and product innovation group."
        skills={["Business Development", "Structural and mechanical engineering","Project Management","Foundation Engineering","Sales","Tribology","Ansys","Python"," Operational software"]}
        link="https://twd.nl"
      />      
      <ExperienceComponent
        date="2018"
        position="R&D Engineer"
        company="DEME Offshore"
        location="Antwerp (BE)"
        description= "Development of a new methodology to implement probabilistic design and data science in the design and risk assessment of new generation offshore wind foundations. Create calculation method for the installation design in difficult non-homogeneous soil conditions. Part of MSC Thesis awarded with a 10/10." 
        skills={["Data Science", "Foundation Engineering","Python","Scientific Research"]}
        link="https://deme-group.com"
      />      
      <ExperienceComponent
        date="2017 - 2018"
        position="Hydraulic Engineer"
        company="Witteveen+Bos"
        location="Jakarta (ID)"
        description="Responsible for interpretation of site investigation data, engineering and consultancy work within the larger projects on flood protection in the Jakarta Bay Area in Indonesia.  "
        skills={["Hydraulic Engineering", "Geotechnical Engineering"]}
        link="https://www.witteveenbos.com"
      />    
      <ExperienceComponent
        date="2015 - 2018"
        position="Fluid Mechanics TA"
        company="TU Delft"
        location="Delft (NL)"
        description="Development of courses and material, guidance and hosting of classes for bachelor & master students and assistance within the section of Environmental Fluid Mechanics. "
        skills={["Fluid Mechanics","Guidance","Education"]}
        link="https://www.tudelft.nl/en/"
      />    
      <ExperienceComponent
        date="2015"
        position="Research Intern"
        company="Deltares"
        location="Delft (NL)"
        description="Creation of numerical model simulating the wave attenuation by shallow vegetation in front of flood defences. Part of BSc Thesis awarded with a 9/10."
        skills={["Hydraulic Engineering","Python","Hydraulic Engineering","Scientific Research"]}
        link="https://www.deltares.nl/en"
      />    
      </div>


      
      <div className="projects"  id="projects">
      <ProjectComponent
        title="Best Estimate"
        description="Development of API-based software and a corresponding blog on quantifying variability for civil/offshore engineering purposes. Expanding my knowledge in statistics & data science as well as programming and software development."
        skills={["Foundation Engineering","Python", "SQL","HTML","Data Science"]}
        imgsource="./bestestimate.png"
        link ="https://www.bestestimate.nl/"
      />
      <ProjectComponent
        title="Heart of Laos"
        description="Commercial support, advice and assistance of a local Lao tour guide who is starting his own trekking agency. Offering remote help to work on his visibility and promotion of his services."
        skills={["React","HTML","Business Development"]}
        imgsource="./heartoflaos.png"
        link ="https://www.heartoflaostrekking.com/"
      />
      <ProjectComponent
        title="Personal Resume App"
        description="Personal resume website build to practice object oriented programming skills using React to add on my existing skills in Python and other back-end languages. Code is freely available on Github."
        skills={["ReactJS","HTML","CSS"]}
        imgsource="./personal.png"
        link="https://www.joostremmers.com"
      />
      <ProjectComponent
        title="Improving the Drainage Capacity of Georgetown"
        description="Part of a group of six engineers performing a project on mapping the flood risk in Georgetown, Guyana. Responsible for the project management and communication as well as the hydraulic modelling."
        skills={["Water Management","Project Management","Hydraulic Modelling"]}
        imgsource="./guyana.png"
        link ="https://repository.tudelft.nl/islandora/object/uuid%3Ac379eeca-5573-4971-ae22-1eaabc0f576c?collection=education"
      />
      </div>

      <div className="publications"  id="publications">
      <PublicationComponent
        title="Reliability based geotechnical installation design"
        location="Ocean Engineering"
        description="A novel approach in foundation installation design for offshore wind foundations. Incorporates soil variability and data science in the engineering process. Journal paper published in Ocean Engineering, main author. "
        link ="https://www.sciencedirect.com/science/article/abs/pii/S0029801819304202"
      />
      <PublicationComponent
        title="Short-term scour development around an offshore piling template"
        description="Publication (co-author) on scour around complex geometries. Project was part of the foundation design of the St. Brieuc drilling template. Collaboration between Van Oord, TWD and Deltares."
        location="ICSE-11"
        link = "https://www.issmge.org/uploads/publications/108/123/Short-term_scour_development_around_an_offshore_pre-piling_template.pdf"
      />
      </div>

      <div className="bio" id="bio">
        <p>
            I built this personal website from scratch using React to improve my skills. Would you like to use it? Download it from <a href="https://github.com/bestestimate" target="_blank" rel="noreferrer">Github</a> and go ahead!
        </p>
       </div>
      </div>
    </div>
  );
};


export default NonMobileInterface;
