// ProjectComponent.js

import React from 'react';
import './Component.css'; // Import the CSS file for styling
import { useMediaQuery } from 'react-responsive';
import LinkIcon from './link.svg';

const ProjectComponent = ({ title, description, skills, imgsource, link}) => {

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  return (
    <div>
    {isMobile ? 

    <div className="blockexp-container_mob">
    <div className="containerexp_mob">
      <div className="left-columnexp">
          <img src= {imgsource} className="imageexp" alt="logo" />
      </div>
      <div className="right-columnexp">
       <a  href= {link} target="_blank" rel="noreferrer">
             <b contentEditable>{title}</b> <img src= {LinkIcon} className="svgicon"  alt="link icon"/>
             <br /> 
        </a>
        <p contentEditable>{description}</p>
        <div>
          {skills.map((skill, index) => (
            <span key={index} className="rounded-box" contentEditable>{skill}</span>
          ))}
        </div>
      </div>
    </div>
    </div>

    :
    <a  href= {link} target="_blank" rel="noreferrer">

    <div className="blockexp-container">
    <div className="containerexp">
      <div className="left-columnexp">
          <img src= {imgsource} className="imageexp" alt="logo" />
      </div>
      <div className="right-columnexp">
        <b contentEditable>{title}</b> <br />
        <p contentEditable>{description}</p>
        <div>
          {skills.map((skill, index) => (
            <span key={index} className="rounded-box" contentEditable>{skill}</span>
          ))}
        </div>
      </div>
    </div>
    </div>
            </a>

  }

    </div>
  );
};

export default ProjectComponent;
