import React from 'react';
import './Component.css'; // Import the CSS file for styling
import { useMediaQuery } from 'react-responsive';
import LinkIcon from './link.svg';

const ExperienceComponent = ({ date, position, company, location, description, skills, link }) => {

  const isMobile = useMediaQuery({ maxWidth: 1050 });
  
  return (
    <div>
    {isMobile ? 
    <div className="blockexp-container_mob">
      <div className="blockexp">

        <a  href= {link} target="_blank" rel="noreferrer">
        <b contentEditable>{position} • {company} </b> <img src= {LinkIcon} className="svgicon" alt="link icon"/><br></br>
        </a>
        <b contentEditable>{date} • {location}  </b> 
        <p contentEditable>{description}</p>        
        <div>
          {skills.map((skill, index) => (
            <span key={index} className="rounded-box" contentEditable>{skill}</span>
          ))}
        </div>
      </div>
    </div>   

    : 

    <a  href= {link} target="_blank" rel="noreferrer">
    <div className="blockexp-container">
      <div className="blockexp">
        <b contentEditable>{date} • {position} • {company} • {location}</b> 
        <p contentEditable>{description}</p>
        <div>
          {skills.map((skill, index) => (
            <span key={index} className="rounded-box" contentEditable>{skill}</span>
          ))}
        </div>
      </div>
    </div>          
    </a>
    }
    </div>
  );
};

export default ExperienceComponent;
